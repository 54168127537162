import React from 'react';
import logo from './assets/images/logomaior.png';
import './styles/App.css';

import {Box} from "@mui/material";

function App() {
  return (
      <div>
          <img src={logo} className="App-logo" alt="logo" />
            <Box>
                <h1>Coming soon...</h1>
            </Box>
      </div>
  );
}

export default App;
